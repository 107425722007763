<template>
<div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div>
                <div>
                  <multiple-select
                    :options="customerList"
                    :id-field="'id'"
                    labelField="nome"
                    :placeholder="this.$i18n.t('form.customer.label')"
                    :fetchOptions="personFetchOptions"
                    :value="filters.filCliente" />
                </div>
              </div>

              <div>
                <div>
                  <multiple-select
                    :options="agentOriginList"
                    :id-field="'id'"
                    labelField="nome"
                    :placeholder="this.$i18n.t('filters.filAgenteOrigem')"
                    :fetchOptions="agentOriginFetchOptions"
                    :value="filters.filAgenteOrigem" />
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <!-- Período -->
              <h6 class="mb-3 mt-5">{{ $t('kpi.volume.period') }}</h6>
              <!-- Data inicial -->
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.begin_date_invoice')"
                               id="begin_date_invoice" ref="begin_date_invoice"
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDtInicioInvoice)">
                        <label :class="{'active': filters.filPeriodoDtInicioInvoice}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Data Final -->
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.end_date_invoice')"
                               id="end_date_invoice" ref="end_date_invoice" class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filPeriodoDtFimInvoice)">
                        <label :class="{'active': filters.filPeriodoDtFimInvoice}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.task.status.active') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.task.status.inactive')
                    }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!--  Actions -->
                <div class="flexbox px-4 py-3 mb-3">
                  <div class="d-flex align-items-center">
                    <div v-if="selected.length > 0">Selecionados:
                      <div>
                        <span  class="btn btn-sm  btn-outline-light mr-1" v-for="invoice in firstSelectedList" v-bind:key="invoice.numeroProcesso">
                          {{ invoice.numeroProcesso }}
                        </span>
                        <span v-if="selected.length > 5" class="btn btn-sm  btn-outline-light">
                          +{{ selected.length - 5 }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="btn-group" role="group" aria-label="false">
                    <a class="btn  btn-outline-light  pull-right" href="javascript:;" v-on:click="btnIssue">
                      {{ $t('actions.issue') }}
                    </a>
                    <div class="dropdown   pull-right">
                    <button class="btn btn-outline-light dropdown-toggle text-muted" type="button" data-toggle="dropdown"
                            aria-expanded="false">
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end"
                         style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(92px, 38px, 0px);">
                      <a class="dropdown-item d-flex align-items-center" href="javascript:;" v-on:click="btnIssue">
                        {{ $t('actions.issue') }}
                      </a>
                      <a v-if="filter.id_status == 1" class="dropdown-item d-flex align-items-center" href="javascript:;" @click="btnRemoveFromList()">
                        {{ $t('actions.remove from list') }}
                      </a>
                      <a v-if="filter.id_status == 2" class="dropdown-item d-flex align-items-center" href="javascript:;" @click="btnReturnToList()">
                        {{ $t('actions.return to list') }}
                      </a>
                    </div>
                  </div>
                  </div>
                </div>
                <!--  End Actions -->
                <!-- Table -->
                <div class="table-responsive">
                  <vuetable ref="vuetable"
                            :noDataTemplate="$t('noting to result')"
                            :api-url="invoiceEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="40"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="idregistroFinanceiro"
                            @vuetable:checkbox-toggled-all="onCheckboxToggledAll"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError"
                            @on-itemclick="onCheckboxToggled"
                  >
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <a  class="btn btn-link btn-padding" :title="$t('actions.log')"
                            v-on:click="showLogs(props.rowData)">
                          <i class="text-primary  ft-file-text font-20"></i>
                        </a>
                      </div>
                    </template>
                    <template slot="cliente" slot-scope="props">
                      {{ props.rowData.cliente }} ({{ props.rowData.clienteCpfCnpj }})
                    </template>
                    <template slot="agenteOrigem" slot-scope="props">
                      {{ props.rowData.agenteOrigem }} {{ props.rowData.agenteOrigemCpfCnpj ? '(' + props.rowData.agenteOrigemCpfCnpj + ')' : '' }}
                    </template>
                  </vuetable>
                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <div class="table-responsive">
                  <div v-if="selected.length > 0" class="pull-right mt-4">
                    Total: {{ $util.formatNumber(totalSelected) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomVueTableSelect from '@/components/Table/CustomVueTableSelect'
import Vue from 'vue'
import dayjs from 'dayjs'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue'
import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// Get services
import MovementTypeService from '@/services/MovementTypeService'
import PersonTypeService from '@/services/PersonTypeService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'
import PersonService from '../../../services/PersonService'

import UpdateStatusView from './UpdateStatusView'
import ShowLogs from './ShowLogs'
Vue.component('vuetable-field-checkbox', VuetableFieldCheckbox)

export default {
  name: 'InvoiceListBill',
  components: {
    FilterSideBar,
    Loading,
    Vuetable,
    VuetablePagination,
    FilterContainer,
    MultipleSelect
  },
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('invoice.list.title') + ' - %s'
    }
  },
  data () {
    return {
      filterStatus: 1,
      invoiceList: [],
      personTypeList: [],
      movementTypeList: [],
      selected: [],
      customerList: [],
      agentOriginList: [],
      isLoading: false,
      fullPage: false,
      invoiceEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/nfse-international',
      multiSort: true,
      activeFilterList: {
        internal_code: null,
        customer: null,
        agent_origin: null,
        id_status: null,
        beginDateInvoice: null,
        endDateInvoice: null
      },
      filter: {},
      sortOrder: [
        { field: 'agenteOrigem', direction: 'asc' },
        { field: 'dataBaixa', direction: 'asc' },
        { field: 'numeroProcesso', direction: 'desc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      }
    }
  },
  watch: {
    filterStatus: function () {
      //
    },
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    selected: function () {
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  },
  computed: {
    totalSelected () {
      let total = this.selected.reduce((a, b) => ({ valorTotal: a.valorTotal + b.valorTotal }))

      return total.valorTotal
    },
    firstSelectedList () {
      let dados = []
      let i = 0
      for (let invoice in this.selected) {
        i++
        dados.push(this.selected[invoice])
        if (i >= 5) {
          break
        }
      }

      return dados
    },
    fieldsTable () {
      return [
        {
          name: CustomVueTableSelect,
          titleClass: 'text-center',
          dataClass: 'text-center'
        },
        {
          name: 'dataFechamentoProcesso',
          title: this.$i18n.t('form.invoices.process_finished_date'),
          sortField: 'dataFechamentoProcesso',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'numeroProcesso',
          title: this.$i18n.t('form.invoices.internal_code'),
          sortField: 'numeroProcesso',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'referenciaCliente',
          title: this.$i18n.t('form.invoices.customer_reference'),
          sortField: 'referenciaCliente',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'cliente',
          title: this.$i18n.t('form.invoices.customer'),
          sortField: 'cli_nome',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'agenteOrigem',
          title: this.$i18n.t('filters.filAgenteOrigem'),
          sortField: 'agenteOrigem',
          dataClass: 'text-left',
          titleClass: 'text-left',
          width: '250px',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'pessoaPadrao.nome',
          title: this.$i18n.t('form.invoices.customer_invoice'),
          sortField: 'PessoaPadraoNome',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tipoMovimentacaoPadrao.name',
          title: this.$i18n.t('form.invoices.movement_type'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'valorTotal',
          sortField: 'valorNota',
          title: this.$i18n.t('form.invoices.amount_real'),
          dataClass: 'text-right',
          titleClass: 'text-right',
          formatter: (value) => this.$util.formatNumber(value)
        },
        {
          name: 'situacao',
          title: this.$i18n.t('form.invoices.invoice_status'),
          sortField: 'situacao',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          visible: (this.filterStatus === 2),
          width: '280px',
          dataClass: 'text-right',
          titleClass: 'text-right'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    process () {
      return this.$route.params.process || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  created () {
    this.$store.dispatch('setInvoiceSelected', this.selected)
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)
    this.movementTypeFetch()
    this.personTypesFetch()

    this.$jQuery('#begin_date_invoice, #end_date_invoice').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    onCheckboxToggled ({ checked, dataItem }) {
      let _this = this
      if (checked) { // Add to selected List
        _this.selected.push(dataItem)
      } else { // Remove from selected list
        let index = _this.selected.map(function (e) { return e.idregistroFinanceiro }).indexOf(dataItem.idregistroFinanceiro)
        if (index !== -1) {
          _this.selected.splice(index, 1)
        }
      }

      this.$store.dispatch('setInvoiceSelected', _this.selected)
    },
    onCheckboxToggledAll (checked) {
      let _this = this
      for (let item in _this.$refs.vuetable.tableData) {
        let dataItem = _this.$refs.vuetable.tableData[item]
        if (checked) { // Add to selected List
          let index = _this.selected.map(function (e) { return e.idregistroFinanceiro }).indexOf(dataItem.idregistroFinanceiro)
          if (index === -1) {
            _this.selected.push(dataItem)
          }
        } else { // Remove from selected list
          let index = _this.selected.map(function (e) { return e.idregistroFinanceiro }).indexOf(dataItem.idregistroFinanceiro)
          if (index !== -1) {
            _this.selected.splice(index, 1)
          }
        }
      }

      this.$store.dispatch('setInvoiceSelected', _this.selected)
    },
    onCloseModal () {
      this.$refs.vuetable.refresh()
    },
    movementTypeFetch () {
      MovementTypeService.getMovementTypes({ type: 2 }, 1, 1000).then(res => {
        this.movementTypeList = res.data.data
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 1000).then(res => {
        this.personTypeList = res.data.data
      })
    },
    onCellClicked ({ data, field, event }) {
      this.$refs.vuetable.toggleDetailRow(data.idregistroFinanceiro)
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      // 1: Em aberto
      // 2: Finalizado
      // 3: Cancelado
      // 4: Parcialmente Quitado

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.processo_aberto'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.em_andamento'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.liberado_faturamento'),
          'color': '#f1ba26'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.faturado'),
          'color': '#f1ba26'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.finalizado'),
          'color': '#5ec65f'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.auditado'),
          'color': '#5ec65f'
        },
        {
          'id': 7,
          'label': this.$i18n.t('form.process.agenciamentoCarga.status.cancelado'),
          'color': '#ff0000'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    dataManager (sortOrder, pagination) {
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getPersons(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    agentOriginFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getCargoAgent(filters).then(res => {
          _this.agentOriginList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    scrollToTop () {
      global.window.scrollTo(0, 0)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
      this.scrollToTop()
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onLoading () {
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
    },
    handleSelect () {
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus

      this.filterStatus = idStatus

      this.$refs.vuetable.refresh()
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    // Loader
    // ------------------------------------------------------------
    // FILTERS
    // ------------------------------------------------------------
    buildFilters () {
      if (this.$route.params.process) {
        this.filter.internal_code = this.$route.params.process
      } else if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
      this.filter.agent_origin = (this.filters.filAgenteOrigem) ? this.filters.filAgenteOrigem.map(x => (x.id)) : []
      this.filter.beginDateInvoice = this.filters.filPeriodoDtInicioInvoice
      this.filter.endDateInvoice = this.filters.filPeriodoDtFimInvoice
      this.filter.id_status = 1
    },
    applyFilter () {
      let dayParse = 'DD/MM/YYYY'
      this.customerList = []
      this.agentOriginList = []

      // Process
      this.filters.filProcesso = this.$refs.internal_code.value

      // Date period
      this.filters.filPeriodoDtInicioInvoice = dayjs(this.$refs.begin_date_invoice.value, dayParse).isValid() ? dayjs(this.$refs.begin_date_invoice.value, dayParse).toISOString() : null
      this.filters.filPeriodoDtFimInvoice = dayjs(this.$refs.end_date_invoice.value, dayParse).isValid() ? dayjs(this.$refs.end_date_invoice.value, dayParse).toISOString() : null

      this.buildFilters()
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    resetFilter (event) {
      event.preventDefault()
      // Reset selected Invoices
      this.selected = []
      this.$store.dispatch('setInvoiceSelected', this.selected)

      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.filters.filAgenteOrigem = []
      this.filters.filProcesso = null

      // Period Date
      this.filters.filPeriodoDtInicioInvoice = (dayjs().add(-1, 'week').date(1).hour(0).minute(0).second(0)).format('YYYY-MM-DDTHH:mm:ss')
      this.filters.filPeriodoDtFimInvoice = dayjs().date(dayjs().daysInMonth()).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss')

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    // ------------------------------------------------------------
    // FILTERS
    // ------------------------------------------------------------
    // ------------------------------------------------------------
    // CALCULATORS
    // ------------------------------------------------------------
    sumTotalTaxesSelected () {
    },
    // ------------------------------------------------------------
    // CALCULATORS
    // ------------------------------------------------------------
    btnIssue () {
      this.$store.dispatch('setInvoiceSelectedManager', this.selected)
      this.$router.push({ name: 'InvoiceInternationalManager' })
    },
    showLogs (rowData) {
      this.$modal.show(ShowLogs, { invoice: rowData },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        }
      )
    },
    btnRemoveFromList () {
      let idsInvoicesSelecionadas = this.selected.map(invoice => {
        return parseInt(invoice.idregistroFinanceiro)
      })
      let data = {}
      data.situacao = 2
      data.invoices = idsInvoicesSelecionadas

      let me = this
      this.$modal.show(UpdateStatusView, { alertData: data },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    },
    btnReturnToList () {
      let idsInvoicesSelecionadas = this.selected.map(invoice => {
        return parseInt(invoice.idregistroFinanceiro)
      })
      let data = {}
      data.situacao = 1
      data.invoices = idsInvoicesSelecionadas

      let me = this
      this.$modal.show(UpdateStatusView, { alertData: data },
        {
          draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '80%',
          height: 'auto'
        },
        {
          'closed': me.onCloseModal
        }
      )
    }
  }
}
</script>

<style>

</style>
