<template>
  <th v-if="isHeader"
      class="vuetable-th-component-checkbox"
  >
    <input type="checkbox"
           @change="toggleAllCheckbox($event)"
           :checked="isAllItemsInCurrentPageSelected()"
    >
  </th>
  <td v-else
      class="vuetable-td-component-checkbox"
  >
    <input type="checkbox"
           @change="toggleCheckbox(rowData, $event)"
           :checked="isSelectedInvoice"
    >
  </td>
</template>
<script>
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin.vue'
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'

export default {
  name: 'vuetable-field-checkbox',
  mixins: [VuetableFieldMixin, VuetableFieldCheckboxMixin],
  methods: {
    toggleCheckbox (dataItem, event) {
      this.vuetable.onCheckboxToggled(event.target.checked, dataItem, dataItem)
      this.$parent.$emit('on-itemclick', {
        checked: event.target.checked,
        dataItem: dataItem
      })
    }
  },
  computed: {
    isSelectedInvoice () {
      let rowData = this.rowData
      let selected = this.$store.getters.getInvoiceSelected

      if (!selected) {
        return false
      }
      let index = selected.map(function (e) { return e.idregistroFinanceiro }).indexOf(rowData.idregistroFinanceiro)

      if (index !== -1) {
        return true
      }
      return false
    },
    invoices () {
      return this.$store.getters.getInvoiceSelected
    }
  }
}
</script>
